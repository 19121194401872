import styled from '@emotion/styled';
import { typography, space, layout } from 'styled-system';

const Footer = styled.div`
  ${typography}
  ${space}
  ${layout}
`;

export default Footer;
